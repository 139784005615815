/* General container styling */
.container {
  max-width: 1000px; /* Set a maximum width for the container */
  margin: 0 auto;    /* Center the container horizontally */
  text-align: center; /* Center the text and images */
  padding: 20px;     /* Add padding for some spacing around the content */
}

/* Hide the desktop image on mobile screens */
.mobile-image {
  display: none;
}

/* Hide the mobile image on desktop screens */
.desktop-image {
  display: block;
  margin: 20px auto; /* Center the image on desktop and add spacing */
  max-width: 800px;  /* Limit the size of the desktop image */
  width: 100%;       /* Make the desktop image responsive */
  height: auto;
}

/* Link styling */
.rsvp-link {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.rsvp-link:hover {
  background-color: #45a049;
}

.error-message {
  color: #81D1D;
  margin-top: 10px;
  font-size: 14px;
}

/* Media query for mobile screens */
@media (max-width: 600px) {
  .desktop-image {
    display: none;
  }

  .mobile-image {
    display: block;
    margin: 0 auto;    /* Center the mobile image */
    width: 100%;       /* Make the mobile image responsive */
    height: auto;
    max-width: 400px;  /* Set a maximum width for the mobile image */
  }
}



