/* RSVP.css */

/* General container styling */
.rsvp-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px; /* Add padding at the bottom to prevent content from being blocked */
}

h1 {
  text-align: center;
}

/* Party image container styling */
.party-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 30px;
}

.party-images {
  width: calc(50% - 10px); /* 50% width with gap for party images */
  height: auto;
  border-radius: 8px;
}

/* Guest list styling */
.guest-list {
  list-style: none;
  padding: 0;
}

.guest-list li {
  margin-bottom: 20px;
}

/* Card layout for each guest */
.guest-card {
  display: flex;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 10px;
/*  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
  padding: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.guest-card-content {
  display: flex;
  gap: 20px;
}

/* Guest image styling */
.guest-card-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

/* Guest details styling */
.guest-details {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure the details run full width */
  text-align: left;
}

/* Guest name styling with Dela Gothic One font */
.guest-details > div strong {
  font-family: 'Dela Gothic One', sans-serif; /* Apply Dela Gothic One */
  font-size: 18px; /* Increase font size */
  color: #333; /* Optional: Set color */
}

/* Chip container styling */
.chip-container {
  display: flex;
  gap: 10px;
  margin-top: 10px; /* Add margin between guest name and chips */
}

.chip {
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: #f0f0f0;
  color: #333;
/*  transition: background-color 0.3s;*/
  box-shadow: none;
  text-align: center;
}

.chip.active {
  background-color: #13493E;
  color: white;
}

.chip:hover {
  background-color: #092A2C !important;
  color: white;
  transform: translateY(0) !important;
}

/* Allergy input container */
.allergy-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

/* Styling for the textarea (multi-line input) */
.input-textarea {
  padding: 8px 12px;
  margin: 5px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-family: 'Arial', sans-serif; /* Set font to Arial */
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  resize: vertical; /* Allow the user to resize the height */
}

.input-textarea:focus {
  border-color: #13493E;
  box-shadow: 0 0 5px #13493E;
  outline: none;
}

/* General input field styling */
.input-field {
  padding: 8px 12px;
  margin: 0px 5px 25px 0px;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input-field:focus {
  border-color: #13493E;
  box-shadow: 0 0 5px #13493E;
  outline: none;
}

/* Button styling */
button {
  padding: 12px 20px;
  background-color: #967B51;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

button:hover:not(:disabled) {
  background-color: #4D3817;
  transform: translateY(-2px);
}

button:active {
  background-color: #388e3c;
  transform: translateY(0);
}


/* Bottom bar styling */
.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #967B51;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.bottom-bar p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.bottom-bar button {
  padding: 10px 20px;
  background-color: #4D3817;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bottom-bar button:disabled {
  background-color: #9e9e9e;
  cursor: not-allowed;
}

.bottom-bar button:hover:not(:disabled) {
  background-color: #3B2C12;
}

/* Error message styling inside the bottom bar */
.error-message-bar {
  margin: 0;
  margin-left: 20px; /* Add space between the guest count and error message */
  color: #FFDADA;
  font-size: 16px;
  font-weight: bold;
}


/* Guest note card styling */
.guest-note-card {
  border: 1px solid black; /* Black border */
  border-radius: 12px; /* Border radius */
  padding: 24px; /* Padding inside the card */
  font-family: 'Crimson Text', serif; /* Crimson Text font */
  font-size: 1.2rem;
  margin-bottom: 20px; /* Space below the card */
  text-align: left;
  
}

.guest-note-card p {
  margin: 0; /* Remove default margin on the paragraph */
}

.party-note {
  margin-top: 15px; /* Add space above the party note */
  margin-bottom: 10px; /* Add space below the party note */
}

.error-message {
  color: #581D1D;
  margin-top: 10px;
  font-size: 14px;
}



/* Media query for smaller screens */
@media (max-width: 600px) {
  .rsvp-container {
    width: 90%;
    padding-bottom: 150px;
  }

  .guest-card {
    flex-direction: column; /* Stack content vertically */
    align-items: center; /* Center-align content */
  }

  .guest-card-content {
    flex-direction: column; /* Stack image and details vertically */
    gap: 10px;
    width: 100%; /* Make the content full width */
    align-items: center;
  }

  .guest-card-image {
    width: 200px; /* Larger image for guest illustration on mobile */
    height: 200px;
    margin-bottom: 10px; /* Add some space below the image */
  }

  .guest-details {
    width: 100%; /* Make guest details take up full width */
    text-align: center;
  }

  .input-textarea {
    width: 100%;
    max-width: 600px;
  }

  .chip-container {
    flex-direction: column; /* Stack chips vertically */
    width: 100%; /* Make chips take up full width */
  }

  .chip {
    width: 100%; /* Make each chip full width */
  }

  /* Make the party images full width on mobile */
  .party-images {
    width: 100%; /* Full width for RSVP images on mobile */
    margin-bottom: 8px;
  }

    .bottom-bar {
    justify-content: center; /* Center the content on mobile */
    flex-direction: column;  /* Stack content vertically */
    text-align: center; /* Center-align text */
    padding: 15px 20px; /* Adjust padding */
  }

  .bottom-bar p {
    margin-bottom: 10px; /* Add space between message and button */
  }

  .error-message-bar {
    margin: 10px 0 0; /* Ensure margin is consistent when error appears */
  }

  .bottom-bar button {
    width: 100%; /* Make the button full width on mobile */
    max-width: 300px;
  }
}
