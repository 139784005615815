
/* Navbar styling */
.navbar {
/*  background: #F7E9CA;*/
  display: flex; /* Flexbox for layout */
  justify-content: left; /* Center the navbar contents horizontally */
  padding: 1rem 2rem;
  width: 100%; /* Ensure the navbar takes up full width */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}


.navbar ul {
    list-style: none;
  display: flex;
  gap: 20px; /* Add space between the links */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.navbar ul li {
  display: inline;
}

.navbar ul li a {
  color: #255153;
  font-weight: 500;
  text-decoration: none;
  padding: 10px;
  font-family: 'Dela Gothic One', sans-serif; 
}

.navbar ul li a:hover {
/*  background-color: #555;*/
  color: #092A2C;
  border-radius: 5px;
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .navbar {
  justify-content: center; /* Center the navbar contents horizontally */
 }
}
